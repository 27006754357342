// ===========================================================================================
// COMPONENTS: HTML Lists
// ===========================================================================================

$theme-html-list-bullet-color: $site-bw-color-main !default;
$theme-html-list-li-separation: $site-gutter-base !default;
$theme-html-list-li-ordered-decorator: "." !default;
$theme-html-list-bullet-icon: true !default;

// ===========================================================================================

// -------------------------------------------------
// List Style Types.
//--------------------------------------------------

// Generic <li> with margin on the left side. (Bullet is not inline)
%theme-li-bullet-left-margin {
  position: relative;
  padding-left: $site-gutter-base*2;

  &:before {
    position: absolute !important;
    left: 0;
  }

  &:after {
    content: none;
  }
}

//--------------------------------------------------

// Bullet: Square.
%theme-square-li {
  padding-bottom: $theme-html-list-li-separation;
  @if (mixin-exists('basic-icons') and $theme-html-list-bullet-icon) {
    @include basic-icons($basic-icons-stop2, 0.6rem, $theme-html-list-bullet-color, middle);
  } @else {
    @include font-icon("Arial", "■", 1.0rem, $theme-html-list-bullet-color, text-top);
    &::before {
      top: 3px;
    }
  }

  &::before {
    padding-right: 10px;
    padding-left: 3px;
  }

  &:after {
    content: none;
  }
}

// Bullet: Hexagon.
%theme-hexagon-li {
  padding-bottom: $theme-html-list-li-separation;
  @include font-icon("Arial", "-", 1.0rem, $theme-html-list-bullet-color, text-top);
  &::before {
    top: 3px;
  }

  &::before {
    padding-right: 10px;
    padding-left: 3px;
  }

  &:after {
    content: none;
  }
}

// Bullet: Circle.
%theme-circle-li {
  @if (mixin-exists('basic-icons') and $theme-html-list-bullet-icon) {
    @include basic-icons($basic-icons-radio-checked2, 0.6rem, $theme-html-list-bullet-color, middle);
    padding-bottom: $theme-html-list-li-separation;
    &::before {
      padding-right: 5px;
      padding-left: 3px;
    }
  } @else {
    @include font-icon("Arial", "●", 1.2rem, $theme-html-list-bullet-color, text-top);
    padding-bottom: $theme-html-list-li-separation;
    &::before {
      padding-right: 10px;
      padding-left: 3px;
    }
  }

  &:after {
    content: none;
  }
}

// Ordered numbers: decimal.
%theme-ordered-li {
  list-style-type: none;
  padding-bottom: $theme-html-list-li-separation;

  &:before {
    left: 2px;
    content: "#{$theme-html-list-li-ordered-decorator}" counter(li);
    counter-increment: li;
    color: $theme-html-list-bullet-color;
    font-weight: 600;
    display: inline-block;
    width: 1em;
    margin-right: 12px;
    text-align: right;
    direction: rtl;
  }

  &:after {
    content: none;
  }
}

// Ordered numbers: Roman.
%theme-ordered-roman-li {
  @extend %theme-ordered-li;

  &:before {
    content: "#{$theme-html-list-li-ordered-decorator}" counter(li, upper-roman);
    counter-increment: li;
  }
}

// Ordered numbers: Numeric with leading zeros.
%theme-ordered-zero-lead-li {
  @extend %theme-ordered-li;

  &:before {
    content: "#{$theme-html-list-li-ordered-decorator}" counter(li, decimal-leading-zero);
    counter-increment: li;
  }
}

// Ordered letters.
%theme-ordered-alpha-li {
  @extend %theme-ordered-li;

  &:before {
    content: "#{$theme-html-list-li-ordered-decorator}" counter(li, lower-alpha);
    counter-increment: li;
  }
}

// Ordered numbers: Ordinal.
%theme-ordered-ordinal-li {
  @extend %theme-ordered-li;

  &:before {
    content: "#{$theme-html-list-li-ordered-decorator}" counter(li) "º";
    counter-increment: li;
  }
}

// Ordered numbers: Ordinal Female.
%theme-ordered-ordinal-fem-li {
  @extend %theme-ordered-li;

  &:before {
    content: "#{$theme-html-list-li-ordered-decorator}" counter(li) "ª";
    counter-increment: li;
  }
}

// -------------------------------------------------
// HTML List Styles.
//--------------------------------------------------
%theme-list-base {
  li {
    p {
      margin: $site-gutter-base 0 0;

      &:first-child:last-child { // unique <p>
        margin: 0;
      }
    }
  }
}

// Basic.
// -------------------------------
%theme-ul-plain {
  margin: 0;
  padding: 0;
}

%theme-ul-no-bullet {
  list-style-type: none;

  li {
    &:before {
      display: none;
    }
  }
}

%theme-ul-inline {
  li {
    display: inline-block;
  }
}

%theme-ul-clean {
  @extend %theme-ul-plain;
  @extend %theme-ul-inline;
  @extend %theme-ul-no-bullet;
}

%theme-ul-bullet-left-margin {
  li {
    @extend %theme-li-bullet-left-margin;
  }
}

// Unordered. (Use this)
// -------------------------------

%theme-ul-base {
  @extend %theme-list-base;
  list-style: none;
  padding-left: 0;
  counter-reset: none;
}

%theme-squares-list {
  @extend %theme-ul-base;

  & > li {
    @extend %theme-square-li;
  }
}

%theme-circles-list {
  @extend %theme-ul-base;

  & > li {
    @extend %theme-circle-li;
  }
}

// Ordered. (Use this)
// -------------------------------

%theme-ol-base {
  @extend %theme-list-base;
  @extend %theme-ul-plain;
  list-style: none;
  counter-reset: li;
}

%theme-decimal-list {
  @extend %theme-ol-base;

  & > li {
    @extend %theme-ordered-li;
  }
}

%theme-zero-lead-list {
  @extend %theme-ol-base;

  & > li {
    @extend %theme-ordered-zero-lead-li;
  }
}

%theme-roman-list {
  @extend %theme-ol-base;

  & > li {
    @extend %theme-ordered-roman-li;
  }
}

%theme-alpha-list {
  @extend %theme-ol-base;

  & > li {
    @extend %theme-ordered-alpha-li;
  }
}

%theme-ordinal-list {
  @extend %theme-ol-base;

  & > li {
    @extend %theme-ordered-ordinal-li;
  }
}

%theme-ordinal-fem-list {
  @extend %theme-ol-base;

  & > li {
    @extend %theme-ordered-ordinal-fem-li;
  }
}

// -------------------------------------------------
//  Lists CSS classes
//--------------------------------------------------

// Unordered.
// -------------------------------

.ul-plain-list {
  @extend %theme-ul-plain;
  @extend %theme-ul-no-bullet;
}

.ul-inline-list {
  @extend %theme-ul-inline;
}

.ul-squares-list {
  @extend %theme-squares-list;
}

.ul-circles-list {
  @extend %theme-circles-list;
}

// Ordered.
// -------------------------------

.ol-decimal-list {
  @extend %theme-decimal-list;
}

.ol-zero-lead-list {
  @extend %theme-zero-lead-list;
}

.ol-roman-list {
  @extend %theme-roman-list;
}

.ol-alpha-list {
  @extend %theme-alpha-list;
}

.ol-ordinal-list {
  @extend %theme-ordinal-list;
}

.ol-ordinal-fem-list {
  @extend %theme-ordinal-fem-list;
}
