// ===========================================================================================
// COMPONENT: Main Loader.
// ===========================================================================================

// Settings
$main-loader-hex-rows: 5 !default; // More elements will need to be added if > 11
$main-loader-hex-size: 30px !default;
$main-loader-hex-gap: $main-loader-hex-size * 0.25 !default;
$main-loader-color: #FFF !default;
$main-loader-animation-type: top-bottom !default; // none|top-bottom|left-right
$main-loader-z-index: 6000 !default;
$main-loader-opacity: 0.95 !default;

// ===========================================================================================

.main-loader {
  @include transition($transition-base);
  @include vertically-centered-container();
  @include fixed(0,0,0,0);
  z-index: -5000;
  opacity: 0;

  &.show {
    z-index: $main-loader-z-index;
    opacity: $main-loader-opacity
  }

  // Other
  $ratio: if($main-loader-hex-size/1px < 35, 0.6, 0.57735);
  $main-loader-hex-width: $main-loader-hex-size;
  $main-loader-hex-height: $main-loader-hex-size*$ratio*2;

  .loader {
    position: relative;
    width: $main-loader-hex-width*$main-loader-hex-rows + ($main-loader-hex-gap*($main-loader-hex-rows - 1));
    height: $main-loader-hex-width*$main-loader-hex-rows + (($main-loader-hex-gap/2)*($main-loader-hex-rows - 1));
    margin: 0 auto;
  }

  .hex {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $main-loader-hex-width;
    height: $main-loader-hex-height/2;
    color: $main-loader-color;
    background-color: currentColor;
    transform: translate(-50%, -50%);
    transform-origin: 0 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: $main-loader-hex-width;
      height: $main-loader-hex-height/2;
      background-color: currentColor;
      // box-shadow: 0px 0px 0px 1px #000 inset;
    }

    &:before {
      transform: rotate(60deg);
    }

    &:after {
      transform: rotate(-60deg);
    }

    $x-dist: $main-loader-hex-width + $main-loader-hex-gap;
    $y-dist: $main-loader-hex-height + $main-loader-hex-gap;
    $el: 1;
    $cols: floor($main-loader-hex-rows/2);
    $even-cols: if($cols % 2 == 0, true, false);
    $x-offset: if($even-cols, -($cols/2) + 0.5, -(($cols - 1)/2));
    $y-offset: -(($main-loader-hex-rows - 1)/2);

    @for $r from 0 through ($main-loader-hex-rows - 1) {
      // Are we still above the middle row?
      @if $y-offset <= 0 {
        $cols: $cols + 1;
        $x-offset: $x-offset - 0.5;
      } @else {
        $cols: $cols - 1;
        $x-offset: $x-offset + 0.5;
      }
      $even-cols: if($cols % 2 == 0, true, false);

      $row-x-offset: $x-offset;
      @for $c from 1 through $cols {
        &:nth-of-type(#{$el}) {
          display: block;

          // Update the position
          margin-left: $row-x-offset * ($x-dist);
          margin-top: $y-offset * ($y-dist*0.75);

          @if $main-loader-animation-type != none {
            animation: scaleIt 1.5s ease-in-out infinite both;
          }

          // Should we animate?
          @if $main-loader-animation-type == top-bottom {
            animation-delay: 0.05*$r*1s;
          } @else if $main-loader-animation-type == left-right {
            animation-delay: 0.05*($c - 1)*1s;
          } @else if $main-loader-animation-type == in-out {
            // animation: scaleIt 2s ease-in-out infinite both 0.05*($c - 1)*1s;
          } @else if $main-loader-animation-type == out-in {
            // animation: scaleIt 2s ease-in-out infinite both 0.05*($c - 1)*1s;
          }
        }

        // Next element
        $el: $el + 1;

        // Increase x-offset but make sure we only use 0 if uneven columns
        $row-x-offset: if($even-cols and $row-x-offset + 1 == 0, 1, $row-x-offset + 1);
      }

      // Update the y-offset
      $y-offset: $y-offset + 1;
    }
  }

  @keyframes scaleIt {
    25%, 100% {
      transform: scale(1) translate(-50%, -50%);
    }
    50% {
      transform: scale(0) translate(-50%, -50%);
    }
  }


}
