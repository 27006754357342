// ===========================================================================================
// CSS ANIMATIONS
// ===========================================================================================
$default-animation-speed: 0.4s !default;

// -------------------------------------
// Transition and transform mixins
// -------------------------------------

// Create keyframes definition.
// - $animation-name: Identifier for keyframes set
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

// transition: <property> || <duration> || <timing-function> || <delay> [, ...];
@mixin transition($transition...) {
  transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
}

@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}

@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin animation($animation...) {
  -moz-animation: $animation;
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin animation-duration($duration...) {
  -moz-animation-duration: $duration;
  -o-animation-duration: $duration;
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// -----------------------------------------------------------------------
// Position Deployment (Transition) . Needs JS class change to: .deployed
// -----------------------------------------------------------------------
// - $prop: Position to animate
// - $deploy: Position when is deployed
// - $offset: Negative value when is collapsed
// - $speed: Speed of the animation.
@mixin position-deploy($prop: top, $deploy:0, $offset: 150px, $speed: $default-animation-speed) {
  @include transition(all $speed ease);
  opacity: 0;
  #{$prop}: -$offset !important;
  pointer-events: none;
  z-index:-1000;

  &.is-deployed {
    opacity: 1;
    #{$prop}: $deploy  !important;
    pointer-events: inherit;
  }
}

// -------------------------------------
// Animation: Spin
// -------------------------------------

// Keyframes
@include keyframes('spin') {
  from {
    @include rotate(0);
  }
  to {
    @include rotate(360);
  }
}

@include keyframes('spin-reverse') {
  from {
    @include rotate(360);
  }
  to {
    @include rotate(0);
  }
}

// Mixins
@mixin spin-loop-animation($speed: $default-animation-speed) {
  @include animation(spin $speed linear 0s infinite);
}

@mixin spin-animation($speed: $default-animation-speed) {
  @include animation(spin $speed ease 0s);
}

@mixin spin-reverse-animation($speed: $default-animation-speed) {
  @include animation(spin-reverse $speed ease 0s);
}

// Classes
.spin-loop {
  @include spin-loop-animation();
}

.spin-animation {
  @include spin-animation();
}

.spin-reverse-animation {
  @include spin-reverse-animation();
}

// -------------------------------------
// Animation: Fade in and Fade out
// -------------------------------------

// Keyframes
@include keyframes('fade-in') {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@include keyframes('fade-out') {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

// Mixins
@mixin fade-in-animation($speed: $default-animation-speed) {
  display: block !important;
  @include animation(fade-in $speed linear 0s forwards);
}

@mixin fade-out-animation($speed: $default-animation-speed) {
  @include animation(fade-out $speed linear 0s forwards);
}

// Classes
.fade-in-animation {
  @include fade-in-animation();
}

.fade-out-animation {
  @include fade-out-animation();
}

// -------------------------------------
// Animations: Move and Fade
// -------------------------------------
@mixin move-and-fade($prop, $initial, $final) {
  0% {
    #{$prop}: $initial;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    #{$prop}: $final;
    opacity: 0;
  }
}

@include keyframes('move-up-and-fade') {
  @include move-and-fade('top', 25px, -25px);
}

@include keyframes('move-down-and-fade') {
  @include move-and-fade('top', -25px, 25px);
}

@include keyframes('move-right-and-fade') {
  @include move-and-fade('left', -25px, 25px);
}

@include keyframes('move-left-and-fade') {
  @include move-and-fade('left', 25px, -25px);
}

// Mixins
@mixin move-up-and-fade-animation($speed: 1s, $cycle: infinite, $delay: 0s) {
  position: relative;
  @include animation(move-up-and-fade $speed linear $delay $cycle);
}

@mixin move-down-and-fade-animation($speed: 1s, $cycle: infinite, $delay: 0s) {
  position: relative;
  @include animation(move-down-and-fade $speed linear $delay $cycle);
}

@mixin move-right-and-fade-animation($speed: 1s, $cycle: infinite, $delay: 0s) {
  position: relative;
  @include animation(move-right-and-fade $speed linear $delay $cycle);
}

@mixin move-left-and-fade-animation($speed: 1s, $cycle: infinite, $delay: 0s) {
  position: relative;
  @include animation(move-left-and-fade $speed linear $delay $cycle);
}

// -------------------------------------
// Animations: Background move
// -------------------------------------

@mixin background-image-move() {
  0% {
    background-position: 30%;
  }
  50% {
    background-position: 70%;
  }
  100% {
    background-position: 30%;
  }
}

@include keyframes('background-image-move') {
  @include background-image-move();
}

// Mixins
@mixin background-image-move($speed: 45s, $cycle: infinite) {
  @include animation(background-image-move $speed linear 0s $cycle);
}


// -------------------------------------
// Animations: Appears & Disappears
// -------------------------------------
@mixin appears($prop, $offset) {
  0% {
    #{$prop}: $offset;
    opacity: 0;
  }
  100% {
    #{$prop}: 0;
    opacity: 1;
  }
}

@include keyframes('appears-nomove') {
  @include appears('left', 0);
}

@include keyframes('appears-up') {
  @include appears('top', 130px);
}

@include keyframes('appears-down') {
  @include appears('top', -130px);
}

@include keyframes('appears-right') {
  @include appears('left', -150px);
}

@include keyframes('appears-left') {
  @include appears('left', 150px);
}

// -- Mixins
@mixin appears-nomove-animation($speed: 1s, $cycle: forwards, $delay: 0s) {
  position: relative;
  @include animation(appears-nomove $speed ease-out $delay $cycle);
}

@mixin appears-up-animation($speed: 1s, $cycle: forwards, $delay: 0s) {
  position: relative;
  @include animation(appears-up $speed ease-out $delay $cycle);
}

@mixin appears-down-animation($speed: 1s, $cycle: forwards, $delay: 0s) {
  position: relative;
  @include animation(appears-down $speed ease-out $delay $cycle);
}

@mixin appears-right-animation($speed: 1s, $cycle: forwards, $delay: 0s) {
  position: relative;
  @include animation(appears-right $speed ease-out $delay $cycle);
}

@mixin appears-left-animation($speed: 1s, $cycle: forwards, $delay: 0s) {
  position: relative;
  @include animation(appears-left $speed ease-out $delay $cycle);
}

// -- Placeholders
%appears-animation {
  opacity: 0;
}

%appears-nomove {
  @include appears-nomove-animation(0.7s, forwards);
}

%appears-up {
  @include appears-up-animation(0.7s, forwards);
}

%appears-down {
  @include appears-down-animation(0.7s, forwards);
}

%appears-left {
  @include appears-left-animation(0.7s, forwards);
}

%appears-right {
  @include appears-right-animation(0.7s, forwards);
}

// Classes.
.appears-animation {
  @extend %appears-animation;
}

.appears-nomove {
  @extend %appears-nomove;
}

.appears-up {
  @extend %appears-up;
}

.appears-down {
  @extend %appears-down;
}

.appears-left {
  @extend %appears-left;
}

.appears-right {
  @extend %appears-right;
}

// -------------------------------------
// Animations: Disappears
// -------------------------------------

// -- Placeholders
%disappears-animation {
  opacity: 1;
}

%disappears-nomove {
  @include appears-nomove-animation(0.7s, reverse forwards);
}

%disappears-up {
  @include appears-down-animation(0.7s, reverse forwards);
}

%disappears-down {
  @include appears-up-animation(0.7s, reverse forwards);
}

%disappears-left {
  @include appears-right-animation(0.7s, reverse forwards);
}

%disappears-right {
  @include appears-left-animation(0.7s, reverse forwards);
}


// Classes.
.disappears-animation {
  @extend %disappears-animation;
}

.disappears-nomove {
  @extend %disappears-nomove;
}

.disappears-up {
  @extend %disappears-up;
}

.disappears-down {
  @extend %disappears-down;
}

.disappears-left {
  @extend %disappears-left;
}

.disappears-right {
  @extend %disappears-right;
}


// -------------------------------------
// Animations: Change Icon
// -------------------------------------

@mixin icon-flip-animation($icon-source: false, $icon-target: false,  $speed: 0.3s, $cycle: forwards, $delay: 0s) {
  $animation-code: 'icon-change-' + random(1000);
  @include keyframes($animation-code) {
    0% {
      @if $icon-source {
        content: $icon-source;
      }
      @include rotateY(0deg);
    }
    50% {
      @include rotateY(90deg);
    }
    100% {
      @if $icon-target {
        content: $icon-target;
      }
      @include rotateY(0deg);
    }
  }
  @include animation($animation-code $speed linear $delay $cycle);
}



// -------------------------------------
// Transitions & Animations REMOVAL
// -------------------------------------

// Remove transition effect.
%no-transition {
  @include transition(unset !important);
  @include transition-duration(0s !important);
}

// Remove animation effect.
%no-animation {
  @include animation(none !important);
  @include animation-duration(0s !important);
}

// Remove animation and transition effect.
%no-transition-animation {
  @extend %no-transition;
  @extend %no-animation;
}

.no-transition {
  @extend %no-transition;
}

.no-animation {
  @extend %no-animation;
}

.no-transition-animation {
  @extend %no-transition-animation;
}
