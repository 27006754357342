// ===========================================================================================
// THEME COMPONENT: Links
// ===========================================================================================

// Theme default link creation.
// - $main-color: Normal state link color
// - $hover-color: Hover state link color
// - $text-decoration: Normal state text decoration
// - $hover-text-decoration: Hover state text decoration
@mixin site-link($main-color: $site-text-color-main, $hover-color: $site-brand-color-highlight,
                  $text-decoration: none, $hover-text-decoration: none) {
  @include transition($transition-base);
  cursor: pointer;
  color: $main-color;
  border-color: $main-color;
  text-decoration: $text-decoration;
  outline: 0;
  &:hover, &:focus {
    outline: 0;
    color: $hover-color;
    border-color: $hover-color;
    text-decoration: $hover-text-decoration;
  }
}

// ===========================================================================================

// -------------------------------------------
// Anchor Links
// -------------------------------------------

a.anchor {
  position: relative;
  visibility: hidden;
  z-index: -1000;
}

section > a.anchor {
  position: absolute;
  top: -100px; // main navbar height aprox.
}
