// ===========================================================================================
// COMMON SASS DEFINITIONS MAIN FILE
// ===========================================================================================

// Main UTILS
@import "utils/functions";
@import "utils/mixins";
@import "utils/placeholders";
@import "utils/classes";

// Basic common styles.
@import "responsive";
@import "hacks";
@import "animations";
@import "mocks";
