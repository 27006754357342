// ===========================================================================================
// SECTION: Can Features
// ===========================================================================================

.container#can-features {

  .can-feature-list {

    &:first-child {
      @include make-col(5);
      padding-left: $site-gutter-base * 2;
      margin-top: $site-gutter-base * 10;
      @include hexagon-char-simple(400px, rgba($site-brand-color-main, 0.35));
      &:after {
        @include absolute(-13%, auto, auto, -5%);
        z-index: -2;
      }
    }

    &:nth-child(2) {
      @include make-col(4);
      margin-top: $site-gutter-base * 5;
      @include hexagon-char-simple(350px, rgba($site-brand-color-third, 0.3));

      &:after {
        @include absolute(-11%, 33%);
        z-index: -2;
      }
    }

    &:last-child {
      @include make-col(3);
      @include hexagon-char-simple(275px, rgba($site-brand-color-main, 0.5));

      &:after {
        @include absolute(-4%, 8%);
        z-index: -2;
      }
    }

    h3 {
      color: $site-brand-color-highlight;
      margin-bottom: $site-gutter-base;
    }

    ul {
      @extend %theme-squares-list;
      font-weight: 500;
      text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
    }

    // Responsive Rules.
    // ----------------------------------------------
    @include breakpoint('lg', 'max') {
      &:nth-child(2) {
        &:after {
          top: -9%;
          right: 15%;
        }
      }
      &:last-child {
        &:after {
          top: -8%;
          right: 0%;
        }
      }
    }

    @include breakpoint('md', 'max') {
      &:nth-child(n) {
        margin-top: $site-gutter-base;
        @include make-col(12);
        padding: 0 10%;
      }
    }

  }

}
