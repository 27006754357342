// ===========================================================================================
// BLOCK: Footer.
// ===========================================================================================

$footer-hexagon-color: $site-bw-color-darker;
$footer-text-color: $site-brand-color-third;

// ===========================================================================================

footer {
  position: relative;
  margin-top: $site-gutter-base * 6;
  font-size: $site-font-size-base * .95;
  text-align: right;
  min-height: 400px;

  // Progressive Gradient
  &:after {
    content: "";
    display: block;
    @include absolute(0, 0, auto, 0);
    z-index: 2;
    @include linear-gradient($site-bg-color-main, rgba($site-bg-color-main, 0));
    height: 70%;
  }

  // SVG hexagon texture.
  #footer-bg {
    @include absolute(0, 0, 0, 0);
    z-index: 1;

    svg {
      @include absolute(0, 0, 0, 0);
      background: $footer-hexagon-color;

      polygon {
        fill: $footer-hexagon-color;
        stroke-width: 1.5;
        stroke: darken($footer-hexagon-color, 6);
      }
    }
  }

  #footer-info {
    padding-top: 200px;
    position: relative;
    z-index: 3;
    color: $footer-text-color;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
    text-align: left;

    > div {
      &:last-child {
        text-align: right;
      }
    }

    #footer-contact {
      font-size: 0.85em;
      line-height: 2em;
      padding-top: $site-gutter-base / 2;

      #contact-email {
        @include site-link();

        &:hover {
          text-decoration: underline;
        }
      }

      .contact-phone {
        @include site-link();
        font-size: .95em;
        letter-spacing: 1px;

        &:hover {
          text-decoration: underline;
        }

        &:before {
          content: '⬢';
          font-size: .7em;
          display: inline-block;
          vertical-align: middle;
          margin-right: 1em;
          margin-left: 1em;
          position: relative;
          top: -1px;
          color: $site-brand-color-main;
        }
      }

      #alia-email {
        @include site-link($site-brand-color-highlight, darken($site-brand-color-highlight, 10%));

        &:hover {
          text-decoration: underline;
        }
      }

    }

    #footer-logo {
      margin-right: -$site-gutter-base * 3;
      margin-bottom: $site-gutter-base*2;

      #footer-title {
        display: inline-block;
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: -0.025em;
        margin-right: 4rem;
        @include hexagon-char-simple(4rem, $site-brand-color-main);

        &:after {
          display: inline;
          position: absolute;
          z-index: -2;
          opacity: 0.4;
          right: -1.8rem;
          top: -0.4rem;
        }

      }
    }
  }

  @include breakpoint('xs', 'max') {

    font-size: $site-font-size-base * .85;

    #footer-info {
      #footer-contact {
        #contact-email {
          display: block;
        }

        .contact-phone:not(:last-child):before {
          display: none;
          content: "";
        }
      }
    }
  }


}
