// ===========================================================================================
// BLOCK: Header.
// ===========================================================================================

header {
  position: relative;
  background-image: url($path-images + 'wine.png');
  background-size: cover;
  background-position: top;
  height: 40vw;
  margin-bottom: $site-gutter-base * 2;

  // Progressive Gradient
  &:after {
    content: "";
    display: block;
    @include absolute(auto, 0, 0, 0);
    @include linear-gradient(rgba($site-bg-color-main, 0), $site-bg-color-main);
    height: 15%;
  }
}

