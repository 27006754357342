// ===========================================================================================
// COMPONENT: Headers.
// ===========================================================================================

$site-title-color: $site-bw-color-lightest !default;

// ===========================================================================================

h1 {
  display: none;
}

h2 {
  font-size: 2.5rem;
  font-weight: 600;
  @include breakpoint('lg', 'max') {
    font-size: 2rem;
  }
  @include breakpoint('md', 'max') {
    font-size: 1.8rem;
  }
  @include breakpoint('xs', 'max') {
    font-size: 1.6rem;
  }
}

h3 {
  font-size: 2rem;
  @include breakpoint('lg', 'max') {
    font-size: 1.8rem;
  }
  @include breakpoint('md', 'max') {
    font-size: 1.5rem;
  }
  @include breakpoint('xs', 'max') {
    font-size: 1.3rem;
  }
}

.title-left {
  @include title-left();
  color: $site-title-color;
  @include breakpoint('lg', 'max') {
    @include title-left(107px);
  }
  @include breakpoint('md', 'max') {
    @include title-left(99px);
  }
  @include breakpoint('xs', 'max') {
    @include title-left(95px);
  }
}

.title-right {
  @include title-right();
  color: $site-title-color;
  @include breakpoint('lg', 'max') {
    @include title-right(107px);
  }
  @include breakpoint('md', 'max') {
    @include title-right(99px);
  }
  @include breakpoint('xs', 'max') {
    @include title-right(95px);
  }
}

.title-right-wrapper {
  width: 100%;
  text-align: right;
}
