// ===========================================================================================
// THEME VARIABLES DEFINITION
// ===========================================================================================

// -------------------------------------
// PATHS
// -------------------------------------
$path-base: '../';
$path-fonts: $path-base + "fonts/";
//$path-libs: $path-base + "lib/";
$path-images: $path-base + 'images/';

// -------------------------------------
// COLORS
// -------------------------------------
$site-brand-color-main: #9F1B7E;
$site-brand-color-secondary: #6C6C6C;
$site-brand-color-third: #BABABA;
$site-brand-color-highlight: rgb(206, 156, 16);

// Black & White palette.
$site-bw-color-main: $site-brand-color-secondary;
$site-bw-color-light: lighten($site-brand-color-secondary, 10%);
$site-bw-color-lighter: lighten($site-brand-color-secondary, 30%);
$site-bw-color-lightest: lighten($site-brand-color-secondary, 60%);
$site-bw-color-dark: darken($site-brand-color-secondary, 10%);
$site-bw-color-darker: darken($site-brand-color-secondary, 28%);
$site-bw-color-darkest: darken($site-brand-color-secondary, 60%);

// -------------------------------------
// GENERAL
// -------------------------------------
$site-bg-color-main: black;
$site-text-color-main: #BABABA;
$site-gutter-base: 15px;

// -------------------------------------
// FONT
// -------------------------------------
$site-font-family-primary: 'MontserratAlternates', Helvetica, arial;
$site-font-weight: 400;
$site-font-size-base: 18px;

// -------------------------------------
// HTML LISTS
// -------------------------------------
$theme-html-list-bullet-color: $site-brand-color-main;

// -------------------------------------
// OVERLAY
// -------------------------------------
$overlay-z-index: 5000;

// -------------------------------------
// LOADER
// -------------------------------------
$main-loader-hex-rows: 3;
$main-loader-hex-size: 32px;
$main-loader-color: $site-brand-color-main;
$main-loader-animation-type: left-right; // none|top-bottom|left-right
$main-loader-z-index: $overlay-z-index + 10;

// -------------------------------------
// MAIN NAVBAR
// -------------------------------------
$burger-menu-color: $site-bw-color-lighter;
$burger-menu-size: 12px;

// -------------------------------------
// RESPONSIVE
// -------------------------------------
$break-points: (// Bootstrap 4 breakpoints
  xxs: (0, 320px),
  xs: (321px, 575px),
  sm: (576px, 767px),
  md: (768px, 991px),
  lg: (992px 1199px),
  xl: 1200px
);

$breakpoint-mobile: 'sm';

// -------------------------------------
// LAYOUT DISPLAY (DEVELOPMENT TOOLS)
// -------------------------------------
$lyd-site-info-bg-color: $site-brand-color-main;
