// ===========================================================================================
// COMPONENT: Main Loader.
// ===========================================================================================

$overlay-color: #000000 !default;
$overlay-opacity: 0.8 !default;
$overlay-z-index: 5000 !default;

// ===========================================================================================

.main-overlay {
  @include transition($transition-base);
  @include fixed(0, 0, 0, 0);
  background-color: $overlay-color;
  z-index: -5000;
  opacity: 0;

  &.show {
    z-index: $overlay-z-index;
    opacity: $overlay-opacity
  }
}
