// ===========================================================================================
// SECTION: Can
// ===========================================================================================

section#can {

  .container#can-features {
    clear: both;
    margin-top: $site-gutter-base * 4;
  }

  .container#can-categories {
    clear: both;
    margin-top: $site-gutter-base * 4;
  }

}
