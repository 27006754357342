// ===========================================================================================
// THEME MIXINS DEFINITION
// ===========================================================================================

// Create a complete Hexagon Shape.
// -- $width: Width of the hexagon.
// -- $background: Background color for the hexagon shape.
// -- $margin: Margin separation between hexagons
@mixin hexagon($width, $background: white, $margin: auto) {
  position: relative;
  display: inline-block;
  $height: 60px / 104px * $width;
  background-color: $background;
  width: $width;
  height: $height;
  @if ($margin == auto) {
    margin: ($height / 2 + 20);
  } @else {
    margin: $margin;
  }
  box-sizing: border-box;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: ceil($height / 2) solid $background;
    border-left: ceil($width / 2) solid transparent;
    border-right: ceil($width / 2) solid transparent;
    position: absolute;
    display: block;
    top: floor(-$height / 2);
  }
  &:after {
    content: ' ';
    width: 0;
    height: 0;
    border-top: solid $background;
    border-top-width: ceil($height / 2);
    border-left: ceil($width/2) solid transparent;
    border-right: ceil($width/2) solid transparent;
    position: absolute;
    display: block;
    top: $height;
  }
}

// Change a defined hexagon color
// hexagon mixin must be applied previously.
// -- $color: new background color for the hexagon shape.
@mixin hexagon-color($color) {
  background-color: $color;
  &:after {
    border-top-color: $color;
  }
  &:before {
    border-bottom-color: $color;
  }
}

// Calculates hexagon width based on its height
@function hexagon-width($height) {
  @return ($height/2) * sqrt(3);
}

// Create a hexagon shape using the HTML special character "Hexagon".
// -- $height: size for the hexagon shape.
// -- $color: Color for the hexagon shape.
@mixin hexagon-char($height, $color) {
  $width: hexagon-width($height);
  /* stylelint-disable-next-line */
  position: relative;
  height: $height;
  width: $width;
  &:after {
    font-family: Arial, Helvetica, sans-serif;
    content: "⬢";
    display: block;
    position: absolute;
    top: 0.5%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    font-size: $height * 1.19;
    line-height: $height * 0.95;
    text-indent: -10%;
    color: $color;
  }
}

// Create a hexagon shape using the HTML special character "Hexagon".
// -- $size: size for the hexagon shape.
// -- $color: Color for the hexagon shape.
@mixin hexagon-char-simple($size, $color) {
  /* stylelint-disable-next-line */
  position: relative;
  &:after {
    font-family: Arial, Helvetica, sans-serif;
    content: "⬢";
    display: block;
    font-size: $size;
    line-height: $size;
    color: $color;
  }
}

// Create a container with hexagon shape on the right side.
// -- $width: Width of the hexagon.
// -- $background: Background color.
@mixin title-background-left($width, $background) {
  $height: 60px / 104px * $width;
  background-color: $background;
  position: relative;
  box-sizing: border-box;

  &:after {
    content: ' ';
    width: 0;
    height: 0;
    border-left: solid $background;
    border-left-width: ($height / 2);
    border-top: ($width/2) solid transparent;
    border-bottom: ($width/2) solid transparent;
    position: absolute;
    display: block;
    top: 0;
    right: -($height/2);
  }
}

// Create a container with hexagon shape on the left side.
// -- $width: Width of the hexagon.
// -- $background: Background color.
@mixin title-background-right($width, $background) {
  $height: 60px / 104px * $width;
  background-color: $background;
  position: relative;
  box-sizing: border-box;

  &:after {
    content: ' ';
    width: 0;
    height: 0;
    border-right: solid $background;
    border-right-width: ($height / 2);
    border-top: ($width/2) solid transparent;
    border-bottom: ($width/2) solid transparent;
    position: absolute;
    display: block;
    top: 0;
    left: -($height/2);
  }
}

// Main section title aligned to left.
// -- $height: Title height
@mixin title-left($height: 115px) {
  @include title-background-left($height, $site-brand-color-main);
  white-space: nowrap;
  padding: $grid-gutter-width 15% $grid-gutter-width 5%;
  display: inline-block;
  text-align: left;
  margin-bottom: $grid-gutter-width*2;
}

// Main section title aligned to right.
// -- $height: Title height
@mixin title-right($height: 115px) {
  @include title-background-right($height, $site-brand-color-main);
  float: right;
  display: inline-block;
  text-align: right;
  margin-bottom: $grid-gutter-width*2;
  padding: $grid-gutter-width 5% $grid-gutter-width 15%;
}

// Hexagonal pattern background.
// -- $size: Size of the pattern.
// -- $primary-color: Color of the pattern
// -- $line-color: Color of the separation lines
@mixin hexagonal-pattern($size: 30px, $primary-color: $site-brand-color-secondary, $line-color: darken($primary-color, 10%)) {
  $transparent: transparent;
  background: radial-gradient(circle farthest-side at 0% 50%, $primary-color 23.5%, $transparent 0) ($size * .7) $size,
  radial-gradient(circle farthest-side at 0% 50%, $line-color 24%, $transparent 0) ($size * .6129) $size,
  linear-gradient($primary-color 14%, $transparent 0, $transparent 85%, $primary-color 0) 0 0,
  linear-gradient(150deg, $primary-color 24%, $line-color 0, $line-color 26%, $transparent 0, $transparent 74%, $line-color 0, $line-color 76%, $primary-color 0) 0 0,
  linear-gradient(30deg, $primary-color 24%, $line-color 0, $line-color 26%, $transparent 0, $transparent 74%, $line-color 0, $line-color 76%, $primary-color 0) 0 0,
  linear-gradient(90deg, $line-color 2%, $primary-color 0, $primary-color 98%, $line-color 0%) 0 0 $primary-color;
  background-size: ($size * 1.333) ($size * 2);
}
