// ===========================================================================================
// THEME: General styles
// ===========================================================================================

body {
  background-color: $site-bg-color-main;
  color: $site-text-color-main;
  margin: 0;
  padding: 0;
  font-size: $site-font-size-base;
  font-family: $site-font-family-primary;
  font-weight: $site-font-weight;
}

html, body {
  overflow-x: hidden;
}

.main-loader.show + #contents-wrapper {
  opacity: 0;
}

section {
  position: relative;
  margin-bottom: $site-gutter-base * 4;
}

ul {
  @extend %theme-ul-base;
}

.container {
  font-size: 1rem;
  @include breakpoint('lg', 'max') {
    font-size:0.96rem;
  }
  @include breakpoint('md', 'max') {
    font-size:0.92rem;
  }
  @include breakpoint('sm', 'max') {
    font-size:0.88rem;
  }
  @include breakpoint('xs', 'max') {
    font-size:0.85rem;
    p {
      text-align: justify;
    }
  }
}

