// ===========================================================================================
// BLOCK: Main Navigation.
// ===========================================================================================

nav.block-main-nav {
  @include transition($transition-base);
  background-color: rgba(0, 0, 0, 0);

  .navbar-collapse {
    @include transition($transition-base);
    justify-content: flex-end;
  }

  .navbar-nav a.nav-link {
    color: rgba(255, 255, 255, 0.7);
  }

  .block-main-menu + .block-lang-selector {
    margin-left: $site-gutter-base;
  }

  /* Scrolling styles */
  @include breakpoint('md', 'min') {
    &.scrolling {
      background-color: rgba(0, 0, 0, 0.75);

      .logo-header {
        margin: $site-gutter-base / 2;
        width: 60%;
      }
    }
  }

  /* Responsive */
  @include breakpoint($breakpoint-mobile, 'max') {
    padding: $site-gutter-base * 0.66;
    background-color: rgba(0, 0, 0, 0.85);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.7);

    .navbar-collapse {
      margin: $site-gutter-base (-$site-gutter-base * 0.66) (-$site-gutter-base * 0.66);
      padding: $site-gutter-base/2 $site-gutter-base $site-gutter-base;
      border-top: 1px solid rgba(255,255,255,0.15);
      &.show {
        border-bottom: 2px solid rgba($site-brand-color-main,0.65);
      }
    }

    .block-main-menu + .block-lang-selector {
      margin-left:0;
      margin-top: $site-gutter-base/3;
      border-top: 1px dashed rgba(255, 255, 255, 0.2);
      padding-top: $site-gutter-base*1.5;
    }

  }

  @include breakpoint('xxs', 'max') {
    padding: $site-gutter-base/2;
  }

}
