// ===========================================================================================
// COMPONENT: Color Classes
// ===========================================================================================

// Creates a CSS class for text, background and border color.
// - $name: Name of the reference.
// - $color: Color value.
@mixin site-color-class($name, $color) {
  .site-#{$name} {
    color: $color;
  }

  .site-bg-#{$name} {
    background-color: $color;
  }

  .site-border-#{$name} {
    border-color: $color;
  }
}

// Classes for Brand Colors.
@include site-color-class('brand-color-main', $site-brand-color-main);
@include site-color-class('brand-color-second', $site-brand-color-secondary);
@include site-color-class('brand-color-third', $site-brand-color-third);
@include site-color-class('brand-color-highlight', $site-brand-color-highlight);

// Classes for Basic Colors.
/*
@include site-color-class('brand-color-basic-blue', $brand-color-basic-blue);
@include site-color-class('brand-color-basic-red', $brand-color-basic-red);
@include site-color-class('brand-color-basic-yellow', $brand-color-basic-yellow);
@include site-color-class('brand-color-basic-green', $brand-color-basic-green);
*/

// Classes for B&W Colors.
@include site-color-class('brand-color-bw-lightest', $site-bw-color-lightest);
@include site-color-class('brand-color-bw-lighter', $site-bw-color-lighter);
@include site-color-class('brand-color-bw-light', $site-bw-color-light);
@include site-color-class('brand-color-bw-main', $site-bw-color-main);
@include site-color-class('brand-color-bw-dark', $site-bw-color-dark);
@include site-color-class('brand-color-bw-darker', $site-bw-color-darker);
@include site-color-class('brand-color-bw-darkest', $site-bw-color-darkest);  ;


