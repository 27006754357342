// ==========================================================================================
// BLOCK: Logo Header Wrapper
// ==========================================================================================

.logo-header-wrapper {
  width: 25%;
  padding: $site-gutter-base/2;

  a {
    img.logo-header {
      @include transition($transition-base);
      width: 100%;
      height: auto;
    }
  }

  @include breakpoint('lg', 'max') {
    width: 30%;
  }

  @include breakpoint($breakpoint-mobile, 'max') {
    padding: 5px;
    width: 25%;
  }

  @include breakpoint('xs', 'max') {
    padding: 0 0 0 $site-gutter-base/2;
    width: 40%;
  }

  @include breakpoint('xxs', 'max') {
    width: 50%;
  }

}
