@charset "utf-8";

// ===========================================================================================
// CSS HACKS MIXINS
// ===========================================================================================
/*
	How to use:

	@include [Mixin_Name]( 'selector', (property value, property value) ) OLD map style

	Example:

	@include only_ie9( '.my_element', (color red) )
	@include only_ff28_above( '.my_element', (
		background-color green,
		display flex,
		margin 2em,
	))

	Tips:
	  - Use firefox mixins before IE mixins. Sometimes Firefox wants to precess the IE css but it can't and skips it's own CSS!

// ----------------------------------- //

	List of Mixins:

	only_ff
	only_ff2
	only_ff2_above
	only_ff3_above
	only_ff6_above
	only_ff16_above
	only_ff21_above
	only_ff24_above
	only_ff25_above
	only_ff26_above
	only_ff27_above
	only_ff28_above
	only_ff30_above
	only_webkit
	only_chrome
	only_safari
	only_safari9
	only_ios
	only_safari_no_ios
	only_opera9_safari2
	only_opera
	only_opera11
	only_edge
	edge_explorer
	only_ie6
	only_ie7
	only_ie7_below
	only_ie8
	only_ie8_below
	only_ie9
	only_ie9_below
	only_ie10_above
	only_ie11
	only_ie11_above
	no_ie6
	only_ie9_saf4_above
	no_ie_safari6

// ----------------------------------- */

/* ----------------------------------- //
//          Firefox CSS Hacks
// ----------------------------------- */

/*--- Only Firefox ---*/
@mixin only_ff($selector, $map) {
  @-moz-document url-prefix() {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Firefox 1.5 and Firefox 2 ---*/
@mixin only_ff2($selector, $map) {
  body:empty #{$selector} {
    $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
    @each $property in ($wrap) {
      $prop: nth($property, 1);
      $value: nth($property, 2);
      #{$prop}: unquote($value);
    }
  }
}

/*--- Only Firefox ≥ 16 ---*/
@mixin only_ff16_above($selector, $map) {
  @supports (-moz-appearance:meterbar) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Firefox ≥ 24 ---*/
@mixin only_ff24_above($selector, $map) {
  @supports (-moz-appearance:meterbar) and (cursor:zoom-in) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Firefox ≥ 25 ---*/
@mixin only_ff25_above($selector, $map) {
  @supports (-moz-appearance:meterbar) and (background-attachment:local) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Firefox ≥ 26 ---*/
@mixin only_ff26_above($selector, $map) {
  @supports (-moz-appearance:meterbar) and (image-orientation:90deg) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Firefox ≥ 27 ---*/
@mixin only_ff27_above($selector, $map) {
  @supports (-moz-appearance:meterbar) and (all:initial) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Firefox ≥ 28 ---*/
@mixin only_ff28_above($selector, $map) {
  @supports (-moz-appearance:meterbar) and (list-style-type:japanese-formal) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Firefox ≥ 30 ---*/
@mixin only_ff30_above($selector, $map) {
  @supports (-moz-appearance:meterbar) and (background-blend-mode:difference,normal) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/* ----------------------------------- //
//          Webkit CSS Hacks
// ----------------------------------- */

/*--- Only Webkit (Chrome, Safari, Opera ≥ 14) ---*/
@mixin only_webkit($selector, $map) {
  .selector:not(*:root) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Chrome 28+, Opera ≥ 14 ---*/
@mixin only_chrome($selector, $map) {
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Safari ≥ 9 ---*/
@mixin only_safari9($selector, $map) {
  @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only iOS Safari ≥ 9 ---*/
@mixin only_ios($selector, $map) {
  @supports (-webkit-text-size-adjust:none) and (not (-ms-accelerator:true)) and (not (-moz-appearance:none)) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only Opera ≤ 9.27, Safari ≤ 2 ---*/
@mixin only_opera9_safari2($selector, $map) {
  #{'html:first-child '} #{$selector} {
    $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
    @each $property in ($wrap) {
      $prop: nth($property, 1);
      $value: nth($property, 2);
      #{$prop}: unquote($value);
    }
  }
}

/* ----------------------------------- //
//     Edge CSS Hacks
// ----------------------------------- */

@mixin only_edge($selector, $map) {
  @supports (-ms-accelerator:true) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

@mixin edge_explorer($selector, $map) {
  _:-ms-lang(x), #{$selector} {
    $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
    @each $property in ($wrap) {
      $prop: nth($property, 1);
      $value: nth($property, 2);
      #{$prop}: unquote($value);
    }
  }
}

/* ----------------------------------- //
//     Internet Explorer CSS Hacks
// ----------------------------------- */

/*--- Only IE ≤ 6 ---*/
@mixin only_ie6($selector, $map) {
  #{$selector} {
    #{'* html '} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only IE7 ---*/
@mixin only_ie7($selector, $map) {
  #{$selector} {
    #{'*+html '} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only IE ≤ 7 ---*/
@mixin only_ie7_below($selector, $map) {
  #{$selector} {
    #{'*'} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only IE8 ---*/
@mixin only_ie8($selector, $map) {
  #{$selector} {
    $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
    @each $property in ($wrap) {
      $prop: nth($property, 1);
      $value: nth($property, 2);
      #{$prop}: unquote($value);
    }
  }
}

/*--- Only IE ≤ 8 ---*/
@mixin only_ie8_below($selector, $map) {
  #{$selector} {
    $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
    @each $property in ($wrap) {
      $prop: nth($property, 1);
      $value: nth($property, 2);
      #{$prop}: unquote($value);
    }
  }
}

/*--- Only IE9 ---*/
@mixin only_ie9($selector, $map) {
  #{$selector} {
    $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
    @each $property in ($wrap) {
      $prop: nth($property, 1);
      $value: nth($property, 2);
      #{$prop}: unquote($value);
    }
  }
}

/*--- Only IE ≤ 9 ---*/
@mixin only_ie9_below($selector, $map) {
  html[lang=\en] #{$selector} {
    $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
    @each $property in ($wrap) {
      $prop: nth($property, 1);
      $value: nth($property, 2);
      #{$prop}: unquote($value);
    }
  }
}

/*--- Only IE10 ---*/
@mixin only_ie10_above($selector, $map) {
  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/*--- Only IE ≤ 11 ---*/
@mixin only_ie11($selector, $map) {
  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value)+'\0';
      }
    }
  }
}

/*--- Only IE ≥ 11 ---*/
@mixin only_ie11_above($selector, $map) {
  #{'_:-ms-fullscreen, :root '} #{$selector} {
    $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
    @each $property in ($wrap) {
      $prop: nth($property, 1);
      $value: nth($property, 2);
      #{$prop}: unquote($value);
    }
  }
}

/*--- Anything but IE6 ---*/
@mixin no_ie6($selector, $map) {
  #{'html>body '} #{$selector} {
    $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
    @each $property in ($wrap) {
      $prop: nth($property, 1);
      $value: nth($property, 2);
      #{$prop}: unquote($value);
    }
  }
}

/*--- Only IE ≥ 9, safari4, android ≥ 2.3 ---*/
@mixin only_ie9_saf4_above($selector, $map) {
  @media screen and (min-width: 0 \0
  ) {
    #{$selector} {
      $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
      @each $property in ($wrap) {
        $prop: nth($property, 1);
        $value: nth($property, 2);
        #{$prop}: unquote($value);
      }
    }
  }
}

/* ----------------------------------- //
//           Other CSS Hacks
// ----------------------------------- */

/*--- Everything but IE and Safari ≤ 6 ---*/
@mixin no_ie_safari6($selector, $map) {
  @media screen {
    @media (min-width: 0px) {
      #{$selector} {
        $wrap: ($map, (clip auto)); // HACK FOR UNIQUE ITEMS ARRAYS
        @each $property in ($wrap) {
          $prop: nth($property, 1);
          $value: nth($property, 2);
          #{$prop}: unquote($value);
        }
      }
    }
  }
}
