// ===========================================================================================
// THEME: Font-face definitions.
// ===========================================================================================

$font-montserrat-a-name: 'MontserratAlternates';
$font-montserrat-a-path: $path-fonts + 'montserrat-alternates/';
$font-montserrat-a-formats: eot woff2 woff ttf otf;

$font-ma-p: $font-montserrat-a-path + $font-montserrat-a-name + '-';

@include font-face($font-montserrat-a-name, $font-ma-p + 'Thin'       , 100, normal, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'ExtraLight' , 200, normal, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'Light'      , 300, normal, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'Regular'    , 400, normal, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'Medium'     , 500, normal, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'SemiBold'   , 600, normal, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'Bold'       , 700, normal, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'ExtraBold'  , 800, normal, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'Black'      , 900, normal, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'ThinItalic'       , 100, italic, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'ExtraLightItalic' , 200, italic, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'LightItalic'      , 300, italic, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'Italic'           , 500, italic, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'SemiBoldItalic'   , 600, italic, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'BoldItalic'       , 700, italic, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'ExtraBoldItalic'  , 800, italic, $font-montserrat-a-formats);
@include font-face($font-montserrat-a-name, $font-ma-p + 'BlackItalic'      , 900, italic, $font-montserrat-a-formats);
