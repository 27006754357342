// ===========================================================================================
// BLOCK: Navbar toggler Button.
// ===========================================================================================

.navbar-toggler {
  @include transition($transition-base);
  outline: none !important;
  border: none;

  .navbar-toggler-icon {
    background-image: none;
  }

}
