// ===========================================================================================
// UTILS: GENERIC FUNCTIONS
// ===========================================================================================

// Substring replacing inside a string.
// - $string: Base string
// - $search: String to replace in base string
// - $replace: Replacement for $search in $string
// return -> Base string with replacements.
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

// Apply calc function terms and operation.
// - $term1: 1st term value (default: percentage)
// - $term2: 2nd term value (default: px)
// - $operation to execute with terms (default: subtract).
// return -> Calc definition.
@function calc-operation($term1: 100%, $term2: 0, $operation: "-") {
  @if (unitless($term1)) {
    $term1: $term1 + "%"
  }
  @if (unitless($term2)) {
    $term2: $term2 + "px"
  }
  $calc-str: "#{$term1} #{$operation} #{$term2}";
  @return calc(#{$calc-str});
}

// Remove units in a number
// - $number: Number to remove units
// return -> Number without units.
@function strip-units($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

// Pixels to Em conversor.
// - $pixels: Amount of pixels to convert in em.
// - $base-size: Base size for calculations.
// return -> Size in em units.
@function px-to-em($pixels, $base-size: 14) {
  @return ($pixels / $base-size) * 1em;
}

// Pixels to Em conversor.
// - $ems: Amount of ems to convert in px.
// - $base-size: Base size for calculations.
// return -> Size in px units.
@function em-to-px($ems, $base-size: 14) {
  $ems: strip-units($ems);
  $base-size: strip-units($base-size);
  @if $ems == 0 {
    @return 0
  }
  @return ($ems * $base-size) * 1px;
}

// A map-set function.
// - $map: Map
// - $key: New element's key
// - $value: New elemet's value
// return -> Map with new element.
@function map-set($map, $key, $value) {
  $new: ($key: $value);
  @return map-merge($map, $new);
}

// Apply styles to actual selector's parent.
// uses @content to define rules.
@mixin parent {
  $parents: ();
  $parent: '';

  @each $selector in & {
    $length: length($selector);
    $index: 0;
    $last-selector: nth($selector, $length);
    @if ($length == 1) {
      @error "Used parent mixin on a top-level selector";
    } @else {
      $index: str-index($last-selector, '::');

      @if ($index) {
        $last-selector: str-slice($last-selector, 1, $index - 1);
      } @else {
        $last-selector: null;
      }
      // Inspect allows us to combine two selectors in one block.
      $parent: inspect(set-nth($selector, $length, #{$last-selector}));
      $parents: join($parents, $parent, comma);
    }
  }
  @at-root #{$parents} {
    @content;
  }
}

// Calculates sqrt of a number.
// - $r: number
@function sqrt($r) {
  $x0: 1;
  $x1: $x0;
  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs(strip-units($r))) / (2 * $x0);
    $x0: $x1;
  }
  @return $x1;
}
