// ===========================================================================================
// SECTION: Can Categories
// ===========================================================================================

.container#can-categories {

  #categories {
    width: 100%;
    margin: 0 auto auto -11%;
    padding: 0;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    li {
      position: relative;
      list-style-type: none;
      width: 27.85714285714286%; /* = (100-2.5) / 3.5 */
      padding-bottom: 32.16760145166612%; /* =  width /0.866 */
      float: left;
      overflow: hidden;
      visibility: hidden;
      @include transform(rotate(-60deg) skewY(30deg));

      &:nth-child(3n+2) {
        margin: 0 1%;
      }

      &:nth-child(6n+4) {
        margin-left: .5%;
      }

      &:nth-child(6n+4),
      &:nth-child(6n+5),
      &:nth-child(6n+6) {
        margin-top: -6.9285714285%;
        margin-bottom: -6.9285714285%;
        @include transform(translateX(50%) rotate(-60deg) skewY(30deg));
      }

      &:nth-child(6n+4):last-child,
      &:nth-child(6n+5):last-child,
      &:nth-child(6n+6):last-child {
        margin-bottom: 0%;
      }

      /*
      * {
        position: absolute;
        visibility: visible;
      }
      */



      // ---------------------------------------------
      // Contents of every cell.
      // ---------------------------------------------
      > div {
        position: absolute;
        visibility: visible;
        cursor: pointer;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size:0.95rem;
        color: #FFFFFF;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
        line-height:1.6em;
        overflow: hidden;
        @include transform(skewY(-30deg) rotate(60deg));
        -webkit-backface-visibility: hidden;
        @include breakpoint('md', 'max') {
          font-size:0.4rem;
        }

        img {
          position: absolute;
          visibility: visible;
          left: -100%;
          right: -100%;
          width: auto;
          height: 100%;
          margin: 0 auto;
        }

        h3,
        p {
          width: 100%;
          position: absolute;
          visibility: visible;
          padding: 0 5%;
          background-color: rgba($site-brand-color-main, 0.93);
          @include transition(top .2s ease-out, bottom .2s ease-out, .2s padding .2s ease-out);
        }

        h3 {
          bottom: 110%;
          font-weight: normal;
          font-size: 1.5em;
          padding-top: 100%;
          padding-bottom: 100%;
          border-bottom: 5px solid rgba(darken($site-brand-color-main, 10%), 0.9);
          background-color: rgba($site-brand-color-main, 0.93);
        }

        p {
          padding-top: 50%;
          top: 110%;
          padding-bottom: 50%;
        }

        // Cell's Hover effect
        // ----------------------------------
        &:hover {
          h3 {
            bottom: 55%;
            padding-bottom: 6%;
          }

          p {
            top: 45%;
            padding-top: 6%;
          }
        }
      }

    }
  }

  @include breakpoint('xs', 'max') {
    display: none;
  }

}
