// ===========================================================================================
// PAGE SECTION: What Enalia is?
// ===========================================================================================

$section-what-hexagon-size: 375px;
$section-what-odoo-logo-height: 100px;

// ===========================================================================================

section#what {

  .container + .container {
    margin-top: 60px;
  }

  // --------------------------
  // Odoo info.
  // --------------------------
  .content-left {
    position: relative;

    img#odoo-logo {
      @include absolute(($section-what-hexagon-size - $section-what-odoo-logo-height)/2);
      z-index: 3;
      height: $section-what-odoo-logo-height;
      margin-left: $section-what-hexagon-size * 1.03;
    }

    .hexagon-container {
      position: relative;
      @include hexagon-char($section-what-hexagon-size, $site-brand-color-third);

      &#hex1 {
        img {
          height: 40%;
        }
      }

      &#hex2 {
        &:after {
          color: $site-brand-color-main; // Change hexagon color.
        }

        top: -$section-what-hexagon-size/4 + $site-gutter-base;
        margin-left: (hexagon-width($section-what-hexagon-size) / 2)  + $site-gutter-base;
      }

      .hexagon-content {
        @include absolute(0, 0, 0, 0);
        @include vertically-centered-container(center);
        overflow: hidden;
        color: white;
        font-size: 0.9em;
        padding: 0 12.5%;
        text-align: center;
      }

    }

    // Responsive --------------------------------------------
    @include breakpoint('lg') {
      $hexagon-height: $section-what-hexagon-size * 0.7;
      $odoo-logo-size: 60px;

      img#odoo-logo {
        top: ($hexagon-height - $odoo-logo-size) / 2;
        height: 50px;
        margin-left: $hexagon-height * 1.10;
      }
      .hexagon-container {
        @include hexagon-char($hexagon-height, $site-brand-color-third);

        &#hex2 {
          top: -$hexagon-height/4 + $site-gutter-base;
          margin-left: (hexagon-width($hexagon-height) / 2)  + $site-gutter-base;
          font-size: 14px;
        }
      }
    }

    @include breakpoint('sm') {
      $hexagon-height: $section-what-hexagon-size * 0.8;
      $odoo-logo-size: 40px;

      img#odoo-logo {
        top: ($hexagon-height - $odoo-logo-size) / 2;
        height: 50px;
        margin-left: $hexagon-height * 1.10;
      }
      .hexagon-container {
        @include hexagon-char($hexagon-height, $site-brand-color-third);

        &#hex2 {
          top: -$hexagon-height/4 + $site-gutter-base;
          margin-left: (hexagon-width($hexagon-height) / 2)  + $site-gutter-base;
          font-size: 14px;
        }
      }
    }

    @include breakpoint('xs', 'max') {
      $hexagon-height: 315px;
      $odoo-logo-size: 50px;
      text-align: center;

      img#odoo-logo {
        position: relative;
        top: 0;
        height: $odoo-logo-size;
        margin: 0 auto $site-gutter-base * 2;
      }

      .hexagon-container {
        @include hexagon-char($hexagon-height, $site-brand-color-third);
        margin: auto;

        &#hex2 {
          font-size: 16px;
          margin-left: auto;
        }
      }
    }


  }

  // --------------------------
  // Mockups info.
  // --------------------------
  .content-right {
    position: relative;

    .img-container {
      @include absolute(0, 0, 0, 0);

      &#mockup-pc {
        img {
          @include absolute(auto, 0, 25%, 0);
          z-index: 1;
          width: 75%;
        }
      }

      &#mockup-tablet {
        img {
          @include absolute(auto, 0, 15%);
          width: 50%;
          z-index: 2;
        }
      }
    }

    // Responsive --------------------------------------------
    @include breakpoint('md', 'max') {
      .img-container#mockup-pc {
        height: 370px;
        position: relative;
      }
    }

    @include breakpoint('sm', 'max') {
      .img-container#mockup-pc {
        height: 250px;
      }
    }

    @include breakpoint('xs', 'max') {
      height: 400px;
      margin-top: -150px;
      .img-container#mockup-pc,
      .img-container#mockup-tablet {
        height: auto;
        top: 0;
        position:absolute;
        img {
          bottom: auto;
        }
      }
      .img-container#mockup-tablet {
        padding-top:44%;
      }
    }

  }

}


