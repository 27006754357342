// ===========================================================================================
// Animated Burgers Style - SLIP animation
// ===========================================================================================
.burger.burger-slip {
  .burger-lines {
    &:after,
    &:before {
      width: 2em;
    }
    &,
    &:after,
    &:before {
      transition: all .2s;
    }
  }

  &.open {
    .burger-lines {
      & {
        transform: translateX(-2em);
        background-color: transparent;
      }
      &:before,
      &:after {
        left: 0.5em;
        top: 0px;
      }
      &:before {
        transform: translateX(2em) rotate(135deg);
      }
      &:after {
        transform: translateX(2em) rotate(-135deg);
      }
    }
  }
}
