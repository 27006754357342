@charset "utf-8";
// ===========================================================================================
// MOCK ELEMENTS
// ===========================================================================================

$mock-def-default-color:  #999999 !default;
$mock-def-primary-color:  #336699 !default;
$mock-def-success-color:  #339933 !default;
$mock-def-warning-color:  #FFCC00 !default;
$mock-def-danger-color:   #AA2222 !default;
$mock-def-info-color:     #6699BB !default;

$mock-block-border-width: 1px !default;
$mock-block-border-style: solid !default;
$mock-block-min-height: 200px !default;

// -------------------------------------
// Mixins
// -------------------------------------

// Define style of a mock block.
// - $base-color: Base color for mock block's background and border.
@mixin create-mock-block($base-color: $mock-def-default-color) {
  min-height: $mock-block-min-height;
  background-color: lighten($base-color, 32%);
  border-color: lighten($base-color, 20%);
  border-width: $mock-block-border-width;
  border-style: $mock-block-border-style;
}

// -------------------------------------
// Placeholders
// -------------------------------------
%mock-block {
  @include create-mock-block($mock-def-default-color);
}

// Bootstrap default style
%mock-block-default {
  @extend %mock-block;
}

// Bootstrap primary style
%mock-block-primary {
  $base-color: $mock-def-primary-color;
  @if variable-exists('brand-primary') {
    $base-color: $brand-primary;
  }
  @include create-mock-block($base-color);
}

// Bootstrap success style
%mock-block-success {
  $base-color: $mock-def-success-color;
  @if variable-exists('brand-success') {
    $base-color: $brand-success;
  }
  @include create-mock-block($base-color);
}

// Bootstrap warning style
%mock-block-warning {
  $base-color: $mock-def-warning-color;
  @if variable-exists('brand-warning') {
    $base-color: $brand-warning;
  }
  @include create-mock-block($base-color);
}

// Bootstrap danger style
%mock-block-danger {
  $base-color: $mock-def-danger-color;
  @if variable-exists('brand-danger') {
    $base-color: $brand-danger;
  }
  @include create-mock-block($base-color);
}

// Bootstrap info style
%mock-block-info {
  $base-color: $mock-def-info-color;
  @if variable-exists('brand-info') {
    $base-color: $brand-info;
  }
  @include create-mock-block($base-color);
}

// -------------------------------------
// Classes
// -------------------------------------
.mock-block-default {
  @extend %mock-block-default;
}

.mock-block-primary {
  @extend %mock-block-primary;
}

.mock-block-success {
  @extend %mock-block-success;
}

.mock-block-warning {
  @extend %mock-block-warning;
}

.mock-block-danger {
  @extend %mock-block-danger;
}

.mock-block-info {
  @extend %mock-block-info;
}
