// ===========================================================================================
// RESPONSIVE LAYOUT UTILS
// ===========================================================================================

// ---------------------------------------------------
// BREAKPOINT DEFINITION (it can be overrided)
// ---------------------------------------------------
$break-points: (
  'xs': (0, 767px),
  'sm': (768px, 991px),
  'md': (992px, 1199px),
  'lg': 1200px
) !default;

// ---------------------------------------------------
// Mixins
// ---------------------------------------------------

// Responsive layouts breakpoint mixin.
// - $bp: Breakpoint index for $break-points variable map.
// - $direction: @media rule -> max, min, only(min - max). If no value uses 'only' rule by default.
//
// Sample:
// @include breakpoint('xs', 'max') {
//   color: red;
// }
//
// Needs $break-points variable => Breakpoints sample map variable (You must use '$break-points' var name)
// $break-points: (
//   'xs': (0, 767px),
//   'sm': (768px, 991px),
//   'md': (992px, 1199px),
//   'lg': 1200px
// );

@mixin breakpoint($bp, $direction: false) {
  @if (map-has-key($break-points, $bp)) {
    $point: map-get($break-points, $bp);
    @if type-of($point) == number and not unitless($point) {
      @media all and (min-width: #{$point}) {
        @content;
      }
    } @else if type-of($point) == list and ((not $direction == false and $direction == 'only') or ($direction == false)) {
      $from: nth($point, 1);
      $to: nth($point, 2);
      @media all and (min-width: #{$from}) and (max-width: #{$to}) {
        @content;
      }
    } @else if type-of($point) == list and not $direction == false and $direction == 'max' {
      $to: nth($point, 2);
      @media all and (max-width: #{$to}) {
        @content;
      }
    } @else if type-of($point) == list and not $direction == false and $direction == 'min' {
      $from: nth($point, 1);
      @media all and (min-width: #{$from}) {
        @content;
      }
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from '#{$bp}'. "
        + "Available breakpoints are: #{map-keys($break-points)}.";
  }
}

// Retrieve previous breakpoint in the breakpoint list
// - $bp: Breakpoint index for $break-points variable map.
// return -> previous breakpoint | null.
@function prev_breakpoint($bp) {
  $keys: map_keys($break-points);
  $index: index($keys, $bp);
  @if ($index) {
    @return nth($break-points, $index - 1);
  } @else {
    @return null;
  }
}

// Retrieve next breakpoint in the breakpoint list
// - $bp: Breakpoint index for $break-points variable map.
// return -> next breakpoint | null.
@function next_breakpoint($bp) {
  $keys: map_keys($break-points);
  $index: index($keys, $bp);
  @if ($index) {
    @return nth($keys, $index+1);
  } @else {
    @return null;
  }
}
