// ==========================================================================================
// BLOCK: Language selector.
// ==========================================================================================

$lang-selector-item-size: 40px !default;
$lang-selector-item-bg-color: $site-brand-color-secondary !default;
$lang-selector-item-selected-bg-color: $site-brand-color-main !default;
$lang-selector-item-hover-bg-color: $site-brand-color-highlight !default;

// ==========================================================================================

.block-lang-selector {

  li.nav-item {
    @include vertically-centered-container(center);
    position: relative;
    width: $lang-selector-item-size;
    padding: 0;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);

    .decorator {
      @include hexagon($lang-selector-item-size * 0.85, $lang-selector-item-bg-color, 0);
      /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
      position: absolute;
      z-index: -1;

      &, &:after, &:before {
        @include transition($transition-base);
      }
    }

    .nav-link {
      background-color: transparent;
    }

    // -----------------------------------
    // Hover style.
    // -----------------------------------
    &:hover {
      .decorator {
        @include hexagon-color($lang-selector-item-hover-bg-color);
      }
    }

    // -----------------------------------
    // Selected style.
    // -----------------------------------
    &.selected-lang {
      width: $lang-selector-item-size * 1.2;
      font-weight: bold;

      .decorator {
        @include hexagon($lang-selector-item-size * 1.2 * 0.85, $lang-selector-item-selected-bg-color, 0);
        /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
        position: absolute;
      }
    }
  }

  // ---------------------------------------
  // Responsive Styles.
  // ---------------------------------------
  @include breakpoint($breakpoint-mobile, 'max') {
    padding: $site-gutter-base/2 0;

    .navbar-nav {
      flex-direction: row;

      li.nav-item {
        margin-right: $site-gutter-base/2;
      }
    }
  }

}
