// ==========================================================================================
// BLOCK: Main Menu
// ==========================================================================================

.block-main-menu {

  .nav-item {

    a.nav-link {
      display: inline-block;
      vertical-align: middle;
      @include transition($transition-base);

      &:hover {
        color: $site-brand-color-highlight;
      }
    }

    &:before {
      content: "⬢";
      display: inline-block;
      vertical-align: middle;
      font-size: 0.6rem;
      padding: 0 $site-gutter-base/4;
      color: $site-brand-color-main;
    }

    &:first-child:before {
      display: none;
    }

  }

  // ---------------------------------------
  // Responsive Styles.
  // ---------------------------------------
  @include breakpoint($breakpoint-mobile, 'max') {
    padding: $site-gutter-base/2 0;

    .nav-item {
      &:before {
        font-size: 0.8rem;
        padding: 0 $site-gutter-base/2 0 0;
      }
      &:first-child:before {
        display: inline-block;
      }
    }
  }

}
