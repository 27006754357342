@charset "utf-8";
// ===========================================================================================
// THEME MAIN SASS FILE
// ===========================================================================================

// Theme's main variables
@import 'website/variables';

// ----------------------------------------------------------------------
// Vendor SASS definitions
// ----------------------------------------------------------------------
/* stylelint-disable */
@import 'vendor/bootstrap/index';
@import 'vendor/animated-burgers/main';
/* stylelint-enable */

// ----------------------------------------------------------------------
// Tools & Utils.
// ----------------------------------------------------------------------

// Common utilities and overrides.
@import 'common/common.main';

// Development Tools
@import 'dev/layout-display';

// ----------------------------------------------------------------------
// Site's general styles.
// ----------------------------------------------------------------------
@import 'website/directives/mixins';
@import 'website/directives/placeholders';
@import 'website/fonts';
@import 'website/general';

// ----------------------------------------------------------------------
// Components (Don't delete this!)
// ----------------------------------------------------------------------
/* group:components */
@import 'website/elements/components/main-loader.comp';
@import 'website/elements/components/main-overlay.comp';
@import 'website/elements/components/basic/colors.comp';
@import 'website/elements/components/basic/headers.comp';
@import 'website/elements/components/basic/html-lists.comp';
@import 'website/elements/components/basic/links.comp';
/* endgroup */

// ----------------------------------------------------------------------
// Blocks (Don't delete this!)
// ----------------------------------------------------------------------
/* group:blocks */
@import 'website/elements/blocks/footer.block';
@import 'website/elements/blocks/header.block';
@import 'website/elements/blocks/main-nav.block';
@import 'website/elements/blocks/main-nav/lang-selector.block';
@import 'website/elements/blocks/main-nav/logo-header';
@import 'website/elements/blocks/main-nav/main-menu.block';
@import 'website/elements/blocks/main-nav/navbar-toggler-button';
/* endgroup */

// ----------------------------------------------------------------------
// Pages (Don't delete this!)
// ----------------------------------------------------------------------
/* group:pages */
@import 'website/elements/pages/sections/can.section';
@import 'website/elements/pages/sections/what.section';
@import 'website/elements/pages/sections/can/can-categories.section';
@import 'website/elements/pages/sections/can/can-features.section';
/* endgroup */
