// ===========================================================================================
// DEV TOOLS: Layout Display Helpers
// ===========================================================================================

$lyd-color-list: (#336699, #6C2F80, #009386, #ED2E2E, #229922, #cc630e) !default;
$lyd-opacity: 0.92 !default;
$lyd-def-label: "[No layouts]" !default;
$lyd-def-color: #FFF !default;
$lyd-def-bg-color: rgba(0, 0, 0, 0.6) !default;
$lyd-def-box-shadow: 0px -2px 6px 0 rgba(0, 0, 0, 0.3) !default;
$lyd-def-text-shadow: 1px 1px rgba(0, 0, 0, 0.3) !default;
$lyd-def-font: Arial, Helvetica, sans-serif !default;
$lyd-def-font-size: 14px !default;
$lyd-def-gutter: 8px !default;
$lyd-def-animation: all 0.22s linear 0s !default;

$lyd-site-info-text-color: #FFF !default;
$lyd-site-info-bg-color: #435362 !default;

// --------------------------------------------------------------------------------------------

%layd-item {
  color: $lyd-def-color;
  background-color: $lyd-def-bg-color;
  box-shadow: $lyd-def-box-shadow;
  text-shadow: $lyd-def-text-shadow;
  font-size: $lyd-def-font-size;
  font-family: $lyd-def-font;
}

%layd-floating-item {
  @extend %layd-item;
  position: fixed;
  z-index: 98;
  bottom: $lyd-def-gutter * 4 + $lyd-def-font-size;
  font-size: 0.9 * $lyd-def-font-size;
  height: 24px;
  line-height: 24px;
  border: 0;
}

%layd-animation {
  transition: $lyd-def-animation;
  -webkit-transition: $lyd-def-animation;
}

// ----------------------------------------------------------------------
// Layout Breakpoints Display
// ----------------------------------------------------------------------
#layd-wrapper {
  position: fixed;
  z-index: 100000;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  &:before {
    @extend %layd-item;
    @extend %layd-animation;
    @if mixin-exists('simple-stripes') {
      @include simple-stripes(rgba(0, 0, 0, 0.1));
    }
    opacity: 1;
    content: $lyd-def-label;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $lyd-def-gutter 15px;
    text-align: center;
  }

  &.layd-hidden {
    &:before {
      opacity: 0;
      transform: translateY(100%)
    }
  }
}

/*  Turn off all displays **/
body.layd-total-off #layd-wrapper {
  display: none;
}

/* Turn off Layout display */
body.layd-total-off #layd-wrapper:before,
body.layd-off #layd-wrapper:before {
  display: none;
}

/*
 * Create Layout Display Breakpoints Media-queries
 */
$color-list-length: length($lyd-color-list);
$index-color: 1;

@if variable-exists('break-points') {
  @each $bp-name, $bp-sizes in $break-points {
    @media (min-width: nth($bp-sizes, 1)) {
      #layd-wrapper:before {
        @if type-of($bp-sizes) == list {
          content: "[#{to-upper-case($bp-name)}] (#{nth($bp-sizes, 1)} - #{nth($bp-sizes, 2)})";
        } @else {
          content: "[#{to-upper-case($bp-name)}] #{nth($bp-sizes, 1)}";
        }
        background-color: rgba(nth($lyd-color-list, ($index-color % $color-list-length) + 1), $lyd-opacity);
      }
    }
    $index-color: $index-color + 1;
  }
}

// ----------------------------------------------------------------------
// Site Info Display
// ----------------------------------------------------------------------
#layd-site-info {
  @extend %layd-floating-item;
  height: auto;
  font-size: $lyd-def-font-size;
  left: 5px;
  padding: 4px 20px;
  background-color: rgba($lyd-site-info-bg-color, $lyd-opacity);
  color: $lyd-site-info-text-color;
  border: 2px solid rgba(0, 0, 0, 0.16);
}

body.layd-off #layd-site-info {
  bottom: $lyd-def-gutter;
}

// ----------------------------------------------------------------------
// Window Measures Display
// ----------------------------------------------------------------------
#layd-measures-info {
  @extend %layd-floating-item;
  right: 38px;
  padding: 0 10px;
}

body.layd-off #layd-measures-info {
  bottom: $lyd-def-gutter;
}

// ----------------------------------------------------------------------
// Action Buttons
// ----------------------------------------------------------------------
#layd-toogle-btn {
  @extend %layd-floating-item;
  @extend %layd-animation;
  right: 5px;
  width: 24px;
  cursor: pointer;
  text-align: center;
  &:before {
    content: "X";
  }
  &:hover {
    background-color: rgba(39, 85, 107, 0.6);
  }

  &.to-show {
    bottom: $lyd-def-gutter;
    z-index: 1001;
    &:before {
      content: "^";
      display: inline-block;
      margin-top: 2px;
      font-size: 16px;
    }
  }
}

body.layd-off #layd-toogle-btn {
  bottom: $lyd-def-gutter;
}
